import React from 'react'
import LiveOnlineFeesRight from './LiveOnlineFeesRight'
import './Course.css';


const CorporateFees = () => {
  return (<>
  <div className='batch-schedule'>
    <div className='left-mode'>
    <p className='mentoring-mode-content'>Corporate Training refers to a tailored learning approach designed specifically for organizations and businesses. This mode of training is customized to meet the unique needs and goals of a company, focusing on equipping employees with the skills and knowledge necessary to excel in their roles and contribute to the organization's success.</p>
   </div>
   <div className='separator'></div>
   <LiveOnlineFeesRight modeType="corporate" enrollText='Contact us'/>
   
   </div>
   </> )
}

export default CorporateFees
