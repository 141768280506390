import React from 'react'

const UserMessages = () => {
  return (<>
    <div className='courses-enrolled'>
    <nav className='dashboard-nav'>
      Messages</nav>
    </div>
    <div className='resume-div'></div>
    </>
  )
}

export default UserMessages